import axios from 'axios';
import { loadConfig } from './config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const config = await loadConfig();

const apiClient = axios.create({
  baseURL: config.base_url,
});

apiClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const errorMessage = error.response.data?.message || `Error: ${error.response.status}`;
      toast.error(errorMessage, { position: 'top-right' });
    } else {
      toast.error('Network Error', { position: 'top-right' });
    }

    return Promise.reject(error);
  }
);

export default apiClient;
