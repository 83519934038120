import React, { useState } from 'react';
import Modal from './Modal';

const payoutsData = [
  { id: 1, amount: 300, from: 'Account 1', to: 'Account 2' },
  { id: 2, amount: 500, from: 'Account 2', to: 'Account 1' },
];

const Payouts = () => {
  const [selectedPayout, setSelectedPayout] = useState(null);
  const [showCreatePayout, setShowCreatePayout] = useState(false);

  const [fromAccount, setFromAccount] = useState('');
  const [toAccount, setToAccount] = useState('');
  const [amount, setAmount] = useState('');

  const handleCreatePayout = () => {
    console.log(`Creating payout of $${amount} from ${fromAccount} to ${toAccount}`);
    setShowCreatePayout(false);
  };

  return (
    <div>
      <h1 className="text-2xl mb-4">Payouts</h1>
      <ul>
        {payoutsData.map(payout => (
          <li key={payout.id} className="mb-2 p-2 border-b">
            <button onClick={() => setSelectedPayout(payout)} className="hover:underline">
              Payout #{payout.id} - ${payout.amount}
            </button>
          </li>
        ))}
      </ul>

      <button className="bg-green-500 text-white p-2 rounded mt-4" onClick={() => setShowCreatePayout(true)}>
        Create Payout
      </button>

      <Modal showModal={selectedPayout} closeModal={() => setSelectedPayout(null)}>
        {selectedPayout && (
          <div>
            <h2>Payout #{selectedPayout.id}</h2>
            <p>Amount: ${selectedPayout.amount}</p>
            <p>From: {selectedPayout.from}</p>
            <p>To: {selectedPayout.to}</p>
          </div>
        )}
      </Modal>

      <Modal showModal={showCreatePayout} closeModal={() => setShowCreatePayout(false)}>
        <div>
          <h2>Create New Payout</h2>
          <input
            type="text"
            placeholder="From Account"
            className="border p-2 w-full mb-2"
            value={fromAccount}
            onChange={(e) => setFromAccount(e.target.value)}
          />
          <input
            type="text"
            placeholder="To Account"
            className="border p-2 w-full mb-2"
            value={toAccount}
            onChange={(e) => setToAccount(e.target.value)}
          />
          <input
            type="number"
            placeholder="Amount"
            className="border p-2 w-full mb-2"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <button className="bg-green-500 text-white p-2 rounded" onClick={handleCreatePayout}>
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Payouts;
