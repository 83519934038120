import React, { useState, useEffect, useCallback } from 'react';
import Modal from './Modal';
import { format } from 'date-fns';
import apiClient from './axiosConfig';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [nextOffset, setNextOffset] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [refundType, setRefundType] = useState(null);
  const [reason, setReason] = useState('');
  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false); // Prevents further retries if error occurs

  const fetchOrders = useCallback(async (offset = null) => {
    if (loading || hasError) return; // Prevent further fetch attempts on error

    setLoading(true);
    setError(null);

    try {
      const response = await apiClient.get('/accounting/api/v1/orders', {
        params: { offset },
      });
      const { data, next_offset } = response.data;
      setOrders((prevOrders) => (offset ? [...prevOrders, ...data] : data));
      setNextOffset(next_offset);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setHasError(true); // Mark error to prevent re-fetching
      if (error.response && error.response.status === 401) {
        setError('Unauthorized. Please log in again.');
      } else {
        setError('Failed to load orders. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  }, [loading, hasError]);

  useEffect(() => {
    if (!hasError) {
      fetchOrders();
    }
  }, [fetchOrders, hasError]);

  const fetchOrderDetails = async (orderId) => {
    if (!orderId) return;
    setError(null);
    try {
      const response = await apiClient.get(`/accounting/api/v1/orders/${orderId}`);
      setSelectedOrder(response.data);
      setActiveTab('details');
    } catch (error) {
      console.error('Error fetching order details:', error);
      setError('Failed to load order details.');
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedItems(selectedOrder.order_lines);
    } else {
      setSelectedItems([]);
    }
  };

  const handleRefundClick = (type) => {
    if (selectedItems.length === 0) {
      alert('Please select at least one item to refund.');
      return;
    }
    setRefundType(type);
    setConfirmDialogVisible(true);
  };

  const confirmRefund = async () => {
    if (!reason.trim()) {
      alert('Please provide a reason for the refund.');
      return;
    }

    const payload = {
      full: refundType === 'full',
      reason,
      order_lines: selectedItems.map((item) => ({ id: item.id })),
    };

    try {
      await apiClient.post(`/accounting/api/v1/orders/${selectedOrder.id}/refund`, payload);
      alert(`${refundType === 'full' ? 'Full' : 'Partial'} refund processed successfully.`);
      setConfirmDialogVisible(false);
      setReason('');
      fetchOrderDetails(selectedOrder.id);
    } catch (error) {
      console.error('Error processing refund:', error);
      alert('Failed to process refund. Please try again.');
    }
  };

  const getRefundButtonText = (type) => {
    const allSelected = selectedItems.length === selectedOrder.order_lines.length;
    return type === 'partial'
      ? allSelected ? 'Partially Refund Entire Order' : 'Partial Refund'
      : allSelected ? 'Full Refund Entire Order' : 'Full Refund';
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Orders</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}

      <ul>
        {orders.map((order, index) => (
          <li key={index} className="mb-2 p-2 border-b">
            <button
              onClick={() => fetchOrderDetails(order.id)}
              className="text-blue-600 hover:underline"
            >
              Order #{order.id} - Total: £{order.total_amount} - {order.user.email}
            </button>
          </li>
        ))}
      </ul>

      {nextOffset !== null && !loading && !hasError && (
        <button
          onClick={() => fetchOrders(nextOffset)}
          className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Load More'}
        </button>
      )}

      {selectedOrder && (
        <Modal showModal={!!selectedOrder} closeModal={() => setSelectedOrder(null)}>
          <div className="p-4">
            <h2 className="text-xl font-semibold mb-4">Order #{selectedOrder.id}</h2>

            <div className="mb-4">
              <button
                className={`mr-4 px-4 py-2 ${activeTab === 'details' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveTab('details')}
              >
                Order Details
              </button>
              <button
                className={`px-4 py-2 ${activeTab === 'refunds' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveTab('refunds')}
              >
                Refunds
              </button>
            </div>

            {activeTab === 'details' && (
              <div>
                <div className="mb-6 p-4 bg-gray-100 border border-gray-300 rounded-md">
                  <h3 className="text-lg font-semibold mb-2">Order Summary</h3>
                  <p><strong>User:</strong> {selectedOrder.user.name} ({selectedOrder.user.email})</p>
                  <p><strong>Event:</strong> {selectedOrder.event.name}</p>
                  <p><strong>Sales Account:</strong> {selectedOrder.sales_account?.name}</p>
                  <p><strong>Total Amount:</strong> £{selectedOrder.total_amount}</p>
                </div>

                <div className="overflow-x-auto">
                  <table className="table-fixed w-full border-collapse border border-gray-300">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="w-12 p-2 border border-gray-300 text-center">
                          <input
                            type="checkbox"
                            onChange={handleSelectAll}
                            checked={selectedItems.length === selectedOrder.order_lines.length}
                          />
                        </th>
                        <th className="w-1/4 p-2 border border-gray-300">Item</th>
                        <th className="w-1/6 p-2 border border-gray-300">Unit Price</th>
                        <th className="w-1/6 p-2 border border-gray-300">Booking Fee</th>
                        <th className="w-1/6 p-2 border border-gray-300">Processing Fee</th>
                        <th className="w-1/6 p-2 border border-gray-300">VAT</th>
                        <th className="w-1/6 p-2 border border-gray-300">Line Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedOrder.order_lines.map((item, index) => (
                        <tr key={index}>
                          <td className="p-2 border border-gray-300 text-center">
                            <input
                              type="checkbox"
                              checked={selectedItems.includes(item)}
                              onChange={() => setSelectedItems(
                                selectedItems.includes(item)
                                  ? selectedItems.filter((selected) => selected !== item)
                                  : [...selectedItems, item]
                              )}
                            />
                          </td>
                          <td className="p-2 border border-gray-300">{item.ticket_type?.name || 'Unknown'}</td>
                          <td className="p-2 border border-gray-300">£{Number(item.unit_price?.amount || 0).toFixed(2)}</td>
                          <td className="p-2 border border-gray-300">£{Number(item.unit_booking_fee?.net || 0).toFixed(2)}</td>
                          <td className="p-2 border border-gray-300">£{Number(item.unit_processing_fee?.net || 0).toFixed(2)}</td>
                          <td className="p-2 border border-gray-300">£{Number(item.unit_tax?.amount || 0).toFixed(2)}</td>
                          <td className="p-2 border border-gray-300">£{Number(item.line_total?.amount || 0).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="mt-4">
                  <button
                    className="bg-yellow-500 text-white px-4 py-2 rounded mr-4"
                    onClick={() => handleRefundClick('partial')}
                    title="Refund just the tickets/addons"
                  >
                    {getRefundButtonText('partial')}
                  </button>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={() => handleRefundClick('full')}
                    title="Refund Tickets/addons + fees, ticket protection, and tax"
                  >
                    {getRefundButtonText('full')}
                  </button>
                </div>
              </div>
            )}

            {activeTab === 'refunds' && (
              <div>
                <h3 className="text-lg font-semibold mb-4">Refunds</h3>
                {selectedOrder.refunds && selectedOrder.refunds.length > 0 ? (
                  <ul>
                    {selectedOrder.refunds.map((refund) => (
                      <li key={refund.id} className="mb-4 p-4 bg-gray-100 border border-gray-300 rounded-md">
                        <p><strong>Refunded Amount:</strong> £{Number(refund.refund_total.amount).toFixed(2)}</p>
                        <p><strong>Reason:</strong> {refund.reason}</p>
                        <p><strong>Processed By:</strong> {refund.created_by?.name || 'Unknown'}</p>
                        <p><strong>Refund Timestamp:</strong> {format(new Date(refund.created_at), 'PPpp')}</p>

                        <div className="overflow-x-auto mt-4">
                          <table className="table-fixed w-full border-collapse border border-gray-300">
                            <thead>
                              <tr className="bg-gray-200">
                                <th className="w-1/4 p-2 border border-gray-300">Item</th>
                                <th className="w-1/6 p-2 border border-gray-300">Unit Price</th>
                                <th className="w-1/6 p-2 border border-gray-300">Booking Fee</th>
                                <th className="w-1/6 p-2 border border-gray-300">Processing Fee</th>
                                <th className="w-1/6 p-2 border border-gray-300">Ticket Protection Fee</th>
                                <th className="w-1/6 p-2 border border-gray-300">VAT</th>
                                <th className="w-1/6 p-2 border border-gray-300">Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {refund.refund_lines.map((lineItem, index) => (
                                <tr key={index}>
                                  <td className="p-2 border border-gray-300">{lineItem.order_line_id || 'Unknown'}</td>
                                  <td className="p-2 border border-gray-300">£{Number(lineItem.refunded_unit_price.amount || 0).toFixed(2)}</td>
                                  <td className="p-2 border border-gray-300">£{Number(lineItem.refunded_booking_fee.net || 0).toFixed(2)}</td>
                                  <td className="p-2 border border-gray-300">£{Number(lineItem.refunded_processing_fee.net || 0).toFixed(2)}</td>
                                  <td className="p-2 border border-gray-300">£{Number(lineItem.refunded_ticket_protection_fee.net || 0).toFixed(2)}</td>
                                  <td className="p-2 border border-gray-300">£{Number(lineItem.refunded_tax.amount || 0).toFixed(2)}</td>
                                  <td className="p-2 border border-gray-300">
                                    £{(
                                      Number(lineItem.refunded_unit_price.amount || 0) +
                                      Number(lineItem.refunded_booking_fee.net || 0) +
                                      Number(lineItem.refunded_processing_fee.net || 0) +
                                      Number(lineItem.refunded_ticket_protection_fee.net || 0) +
                                      Number(lineItem.refunded_tax.amount || 0)
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No refunds available for this order.</p>
                )}
              </div>
            )}
          </div>
        </Modal>
      )}

      {confirmDialogVisible && (
        <Modal showModal={confirmDialogVisible} closeModal={() => setConfirmDialogVisible(false)}>
          <div className="p-4">
            <h2 className="text-xl font-semibold mb-4">Enter Reason for Refund</h2>
            <textarea
              className="w-full p-2 border border-gray-300 rounded mb-4"
              placeholder="Enter the reason for the refund"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-4"
                onClick={confirmRefund}
              >
                Confirm
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded"
                onClick={() => setConfirmDialogVisible(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Orders;
