import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Accounts from './Accounts';
import Orders from './Orders';
import Payouts from './Payouts';
import Callback from './Callback';
import { generateCodeVerifier, generateCodeChallenge } from './pkce';
import { loadConfig } from './config';
import {ToastContainer} from "react-toastify";
import Transaction from "./Transaction";

const App = () => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      const loadedConfig = await loadConfig();
      setConfig(loadedConfig);
    };

    fetchConfig();
  }, []);

  const handleLogin = async () => {
    if (!config) {
      console.error("Config not loaded yet");
      return;
    }

    const codeVerifier = generateCodeVerifier();
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    localStorage.setItem('pkce_code_verifier', codeVerifier);

    const params = new URLSearchParams({
      response_type: 'code',
      client_id: config.client_id,
      redirect_uri: config.redirect_uri,
      scope: config.scope,
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
    });

    window.location.href = `${config.authorization_endpoint}?${params.toString()}`;
  };

  return (
    <Router>
      <ToastContainer />
      <div className="p-4">
        <nav className="mb-4">
          <ul className="flex space-x-4">
            <li>
              <Link to="/accounts" className="text-blue-500 hover:underline">Accounts</Link>
            </li>
            <li>
              <Link to="/orders" className="text-blue-500 hover:underline">Orders</Link>
            </li>
            <li>
              <Link to="/payouts" className="text-blue-500 hover:underline">Payouts</Link>
            </li>
            <li>
              <button onClick={handleLogin} className="text-blue-500 hover:underline">Login</button>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/transaction/:id" element={<Transaction />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/payouts" element={<Payouts />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/" element={
            <div>
              <h1>Welcome to the Accounting App</h1>
              <p>Select a page from the navigation above or log in to continue.</p>
            </div>
          } />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
