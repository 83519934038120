import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import { format } from 'date-fns';
import apiClient from './axiosConfig';

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountEntries, setAccountEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [error, setError] = useState(null); // State to store error message

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await apiClient.get('/accounting/api/v1/accounts');
        setAccounts(response.data.data || []);
      } catch (error) {
        setError('Failed to load accounts. Please try again later.');
        setAccounts([]);
      } finally {
        setLoading(false);
      }
    };
    fetchAccounts();
  }, []);

  const fetchAccountDetails = async (accountId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient.get(`/accounting/api/v1/accounts/${accountId}`);
      setSelectedAccount(response.data);
      setAccountEntries(response.data.entries || []);
    } catch (error) {
      setError('Failed to load account details.');
    } finally {
      setLoading(false);
    }
  };

  const fetchTransactionDetails = async (transactionUrl) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient.get(transactionUrl);
      setTransaction(response.data);
    } catch (error) {
      setError('Failed to load transaction details.');
    } finally {
      setLoading(false);
    }
  };

  const handleAccountClick = (account) => {
    fetchAccountDetails(account.id);
  };

  const handleTransactionClick = (transactionUrl) => {
    fetchTransactionDetails(transactionUrl);
  };

  const handleBackToEntries = () => {
    setTransaction(null);
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Accounts</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}

      <ul>
        {accounts.map((account) => (
          <li key={account.id} className="mb-2 p-2 border-b">
            <button
              onClick={() => handleAccountClick(account)}
              className="text-blue-600 hover:underline"
            >
              {account.name} (ID: {account.id}) - Category: {account.category}
            </button>
          </li>
        ))}
      </ul>

      {selectedAccount && (
        <Modal showModal={!!selectedAccount} closeModal={() => setSelectedAccount(null)}>
          <div className="p-4">
            {transaction ? (
              <>
                <button
                  onClick={handleBackToEntries}
                  className="text-blue-500 hover:underline mb-4"
                >
                  &larr; Back to Entries
                </button>
                <h2 className="text-xl font-semibold mb-4">Transaction Details</h2>
                <p><strong>Transaction ID:</strong> {transaction.id}</p>
                <p><strong>Description:</strong> {transaction.description || 'N/A'}</p>
                <p><strong>Date:</strong> {format(new Date(transaction.timestamp), 'yyyy-MM-dd HH:mm:ss')}</p>
                <p><strong>Reverted By:</strong> {transaction.reverted_by || 'N/A'}</p>

                <h3 className="text-lg font-semibold mt-4 mb-2">Entries</h3>

                <div className="overflow-x-auto">
                  <table className="table-fixed w-full border-collapse border border-gray-300 text-sm">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="w-1/5 p-2 border border-gray-300">Account Name</th>
                        <th className="w-1/5 p-2 border border-gray-300">Account Category</th>
                        <th className="w-1/5 p-2 border border-gray-300">Debit</th>
                        <th className="w-1/5 p-2 border border-gray-300">Credit</th>
                        <th className="w-1/5 p-2 border border-gray-300 text-xs">Tax</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transaction.entries.map((entry) => (
                        <tr key={entry.id}>
                          <td className="p-2 border border-gray-300">{entry.account.name}</td>
                          <td className="p-2 border border-gray-300">{entry.account.category}</td>
                          <td className="p-2 border border-gray-300">
                            {entry.debit ? `£${parseFloat(entry.debit).toFixed(2)}` : '-'}
                          </td>
                          <td className="p-2 border border-gray-300">
                            {entry.credit ? `£${parseFloat(entry.credit).toFixed(2)}` : '-'}
                          </td>
                          <td className="p-2 border border-gray-300 text-xs text-gray-600">
                            {entry.tax ? `£${parseFloat(entry.tax).toFixed(2)}` : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <h2 className="text-xl font-semibold mb-4">Entries for {selectedAccount.name}</h2>
                {loading ? (
                  <p>Loading account details...</p>
                ) : (
                  <div className="overflow-x-auto">
                    <table className="table-fixed w-full border-collapse border border-gray-300 text-sm">
                      <thead>
                        <tr className="bg-gray-200">
                          <th className="w-1/4 p-2 border border-gray-300">Timestamp</th>
                          <th className="w-1/4 p-2 border border-gray-300">Debit</th>
                          <th className="w-1/4 p-2 border border-gray-300">Credit</th>
                          <th className="w-1/4 p-2 border border-gray-300">Cumulative Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {accountEntries.map((entry) => (
                          <tr key={entry.id}>
                            <td className="p-2 border border-gray-300">
                              <button
                                onClick={() => handleTransactionClick(entry.transaction_url)}
                                className="text-blue-600 hover:underline"
                              >
                                {format(new Date(entry.timestamp), 'yyyy-MM-dd HH:mm')}
                              </button>
                            </td>
                            <td className="p-2 border border-gray-300">
                              {entry.debit ? `£${parseFloat(entry.debit).toFixed(2)}` : '-'}
                            </td>
                            <td className="p-2 border border-gray-300">
                              {entry.credit ? `£${parseFloat(entry.credit).toFixed(2)}` : '-'}
                            </td>
                            <td className="p-2 border border-gray-300">
                              £{parseFloat(entry.cumulative_balance).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Accounts;
