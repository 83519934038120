import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { loadConfig } from './config';

const Callback = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      const authorizationCode = params.get('code');

      if (!authorizationCode) {
        setError('Authorization code not found');
        setLoading(false);
        return;
      }

      const codeVerifier = localStorage.getItem('pkce_code_verifier');
      if (!codeVerifier) {
        setError('Code verifier not found');
        setLoading(false);
        return;
      }

      try {
        const config = await loadConfig();

        const response = await axios.post(config.token_endpoint, {
          grant_type: 'authorization_code',
          client_id: config.client_id,
          code: authorizationCode,
          redirect_uri: config.redirect_uri,
          code_verifier: codeVerifier,
        });

        const { access_token, refresh_token, expires_in } = response.data;

        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem('token_expiry', Date.now() + expires_in * 1000);

        setLoading(false);
        window.location.href = '/';
      } catch (tokenError) {
        console.error('Error exchanging code for token', tokenError);
        setError('Failed to exchange authorization code for access token.');
        setLoading(false);
      }
    };

    handleCallback();
  }, []);

  return (
    <div>
      {loading ? <p>Loading...</p> : error ? <p>{error}</p> : <p>Login successful! Redirecting...</p>}
    </div>
  );
};

export default Callback;
