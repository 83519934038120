import React, { useState, useEffect } from 'react';
import apiClient from './axiosConfig';
import { format } from 'date-fns';

const Transaction = ({ transactionId }) => {
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const response = await apiClient.get(`/accounting/api/v1/transactions/${transactionId}`);
        setTransaction(response.data);
      } catch (error) {
        console.error('Error fetching transaction:', error);
      }
    };
    fetchTransaction();
  }, [transactionId]);

  if (!transaction) return <p>Loading transaction details...</p>;

  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">Transaction Details</h2>

      <p><strong>Transaction ID:</strong> {transaction.id}</p>
      <p><strong>Description:</strong> {transaction.description || 'N/A'}</p>
      <p><strong>Date:</strong> {format(new Date(transaction.timestamp), 'yyyy-MM-dd HH:mm:ss')}</p>
      <p><strong>Reverted By:</strong> {transaction.reverted_by ? transaction.reverted_by : 'N/A'}</p>

      <h3 className="text-lg font-semibold mt-4 mb-2">Entries</h3>

      <div className="overflow-x-auto">
        <table className="table-fixed w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="w-1/5 p-2 border border-gray-300">Account Name</th>
              <th className="w-1/5 p-2 border border-gray-300">Account Category</th>
              <th className="w-1/5 p-2 border border-gray-300">Debit</th>
              <th className="w-1/5 p-2 border border-gray-300">Credit</th>
            </tr>
          </thead>
          <tbody>
            {transaction.entries.map((entry) => (
              <tr key={entry.id}>
                <td className="p-2 border border-gray-300">{entry.account.name}</td>
                <td className="p-2 border border-gray-300">{entry.account.category}</td>
                <td className="p-2 border border-gray-300">
                  {entry.debit ? `£${parseFloat(entry.debit).toFixed(2)}` : '-'}
                </td>
                <td className="p-2 border border-gray-300">
                  {entry.credit ? `£${parseFloat(entry.credit).toFixed(2)}` : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Transaction;
