import React from 'react';

const Modal = ({ showModal, closeModal, children }) => {
  if (!showModal) return null;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-2">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-screen-xl h-[90vh] overflow-auto">
          <button className="text-red-500 float-right text-2xl" onClick={closeModal}>
            &#x2715;
          </button>
          <div className="mt-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
